<template>
  <div class="columns is-multiline">
    <div class="column is-12 is-paddingless is-marginless has-text-left titleCard">
      Productos Comprados
    </div>
    <div class="column is-12 separator"></div>
    <template v-if="products.length > 0">
      <div
        class="column is-4 productList"
        v-for="(product, index) of products"
        :key="index"
      >
        <product-card
          :productId="product.productId"
          :productName="product.name"
          :productImage="product.productImage"
          :productDesciption="product.description"
          :price="product.price"
          :productCondition="product.condition"
          :productStatus="product.status"
          :productStatusId="product.statusId"
        />
      </div>
    </template>
    <div class="column is-12"  v-if="products.length > 0">
      <base-pagination
        class="is-medium"
        :page="page"
        :pages="Math.ceil(totalProducts/6)"
        :action="paginator"
        :loading="loading"
      />
    </div>
    <div class="column is-12 has-text-centered is-empty" v-if="products.length === 0">
      El usuario no ha comprado productos
    </div>
  </div>
</template>

<script>
export default {
  components: {
    ProductCard: () => import('@/components/Products/ProductCard'),
    BasePagination: () => import('@/components/BasePagination')
  },
  props: {
    products: {
      type: Array
    },
    page: Number,
    totalProducts: Number,
    loading: Boolean,
    paginator: {
      type: Function,
      default: () => {}
    }
  },
  methods: {

  }
}
</script>

<style scoped>
  .is-empty {
    font-size: 12px;
  }
</style>
